import "./App.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Homea from "./components/homea/Homea";
import { useEffect, useState } from "react";
// import Important from "./components/important/Important";
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className={`scroll-to-top ${isVisible ? "visible" : "hidden"}`}
      onClick={scrollToTop}
    >
      <i class="fa-solid fa-chevron-up fa-xl" style={{ color: "#ffffff" }}></i>
    </button>
  );
};
function App() {
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollButton = document.querySelector(".scroll-to-top");

      // Adjust the threshold and positioning based on your preferences
      if (scrollTop > 200) {
        scrollButton.style.bottom = "20px";
        scrollButton.style.opacity = "1";
      } else {
        scrollButton.style.bottom = "-50px";
        scrollButton.style.opacity = "0";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  return (
    <>
      <Header />
      <div className="app">
        <Homea />
        {/* <Important /> */}
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
}

export default App;
