import React, { useState } from "react";
import "./Collapsfaq.scss";

const Collapsfaq = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="collfaq sss">
      <div>
        <button
          className={`collapsiblefaq ${isActive ? "active" : ""}`}
          onClick={handleToggle}
        >
          <div className="titlefaq">
            <p>{title}</p>
          </div>
          <div className="plusfaq">
            {isActive ? (
              <i class="fa-solid fa-angle-up" style={{color: "white"}}></i>
            ) : (
              <i class="fa-solid fa-angle-down" style={{color: "white"}}></i>
            )}
          </div>
        </button>
      </div>
      {isActive && <div className="contentfaq ss">{children}</div>}
    </div>
  );
};

export default Collapsfaq;
