import React, { useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../assets/logo.webp";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Set isScrolled to true after scrolling a certain amount (e.g., 50 pixels)
      setIsScrolled(window.scrollY > 50);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  return (
    <header className={isScrolled ? "scrolled" : ""}>
      <div className="header-container center">
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
        <nav>
          <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={menuOpen ? "open" : ""}>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/play">Play</a>
            </li>
            <li>
              <a href="/download">Download</a>
            </li>
            <li>
              <a href="/slot">Slot</a>
            </li>
            <li>
              <a href="/machie">Machine</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
