import React, { useEffect, useState } from "react";
import "./Homea.scss";
import Collaps from "../collaps/Collaps";
import Collapsfaq from "../collapsfaq/Collapsfaq";
import Img2 from "../../assets/section5_img.webp";
import Img3 from "../../assets/section6_img.webp";
import Img4 from "../../assets/section10_img.jpg";
import Img5 from "../../assets/section11_img.webp";
import Img6 from "../../assets/section12_img.jpg";
import Img7 from "../../assets/section13_img.webp";
import Img8 from "../../assets/img_logo_center.webp";
import { Link } from "react-scroll";

const Homea = () => {
  const initialLogs = ["", "", ""];
  const [log, setLog] = useState(initialLogs);
  const casinos = ["Monro", "CatCasino", "Daddy", "Gamma"];
  const nickNames = [
    "Fill D.",
    "Thunder Zoo",
    "Petr 17",
    "Full H",
    "Zoya13",
    "Postmod3",
    "Kill time",
    "Bro Bred",
    "Soft Bill",
  ];
  const currencies = ["$"];
  const icons = ["💎", "💰", "💸"];

  const getRandomElement = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  const generateRandomLog = () => {
    const currentDate = new Date()
      .toISOString()
      .replace("T", " ")
      .split(".")[0];
    const casino = getRandomElement(casinos);
    const nickname = getRandomElement(nickNames);
    const slot = "Big Bamboo"; // You can change this to any desired slot game
    const maxWinning = 21000;
    const winning = Math.floor(Math.random() * (maxWinning + 1)); // Generating random winning amount up to $21,000
    const currency = getRandomElement(currencies);
    const icon = getRandomElement(icons);

    const newLog = `${currentDate}, Casino: ${casino}, Nickname: ${nickname}, Slot: ${slot}, Winning: ${winning}${currency} ${icon}`;

    // Randomly assign the log to one of the three positions
    const randomPosition = Math.floor(Math.random() * 3);
    setLog((prevLogs) => {
      const newLogs = [...prevLogs];
      newLogs[randomPosition] = newLog;
      return newLogs;
    });
  };

  useEffect(() => {
    // Initial log
    generateRandomLog();
    generateRandomLog();
    generateRandomLog();

    // Set up interval to update log every 5 seconds
    const intervalId = setInterval(() => {
      generateRandomLog();
    }, 3500);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div className="section section1" id="section1">
        <div className="wrapper">
          <div className="container">
            <h1>
              <span>Big Bamboo &nbsp;</span>
              slot official website
            </h1>
            <p>375% first deposit bonus + 150 FS </p>
            <div className="row-buttons">
              <a href="rulette.html">
                <button className="button button-orange">GET BONUS</button>
              </a>
              <a className="button button-green button-anim" href="">
                PLAY
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section section2">
        <div className="wrapper">
          <div className="drop-select drop-select-nav active">
            <Collaps title="PAGE NAVIGATION">
              <div
                className="drop-select-container dropdownContent"
                id="myDropdown"
              >
                <ol>
                  <li>
                    <Link
                      to="1"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-140}
                    >
                      Where to play Big Bamboo
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="2"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-140}
                    >
                      Meeting place with luck
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="3"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-140}
                    >
                      Possibility of big winnings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="4"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-140}
                    >
                      Game symbols
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="5"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-140}
                    >
                      Questions and answers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="6"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-140}
                    >
                      Important symbols in the game
                    </Link>
                  </li>
                </ol>
              </div>
            </Collaps>
          </div>
          <div className="container">
            <h2 className="section-h2">
              <span>Big Bamboo &nbsp;</span>
              slot official website
            </h2>
            <p>
              Big Bamboo is not just a slot, it is a journey into the world of
              secrets and mysteries. The design of the game creates an
              atmosphere of mysticism, and the beneficial features and prize
              symbols make the game truly exciting. Feel the excitement and
              excitement in every spin.
            </p>
            <p>
              As gamers, we all look for those games that grab us at first
              sight. Sometimes we want to find ourselves in amazing worlds full
              of secrets and mysteries, and in this regard, “Big Bamboo” differs
              from many other slot machines.
            </p>
            <div className=" column-buttons1 ">
              <a
                className="button button-green button-anim "
                href="https://colorful-road-three.com/d35db545f"
                target="_blank"
              >
                PLAY
              </a>
              <a href="rulette.html">
                <button className="button button-orange">GET A BONUS</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section section3">
        <div className="wrapper">
          <h2 className="section-h2" id="1">
            Where to play Big Bamboo
          </h2>
          <div className="container">
            <div className="items-group">
              <div className="item">
                <div className="item-sticker sticker-anim need-anim-pulse active">
                  <p>Mounth Selection</p>
                </div>
                <div className="item-logo logo-gamma logo-anim need-anim-scale active"></div>
                <div className="item-tittle">
                  <p>Gama</p>
                </div>
                <div className="item-description">
                  <p>Bonus 375% + 150 FS</p>
                </div>
                <div className="item-buttons">
                  <a href="rulette.html">
                    <button className="button button-orange">GET BONUS</button>
                  </a>
                  <a
                    className="button button-green"
                    href="https://colorful-road-three.com/d35db545f"
                    target="_blank"
                  >
                    PLAY
                  </a>
                </div>
              </div>

              
            </div>
            <div className="items-group">
              <div className="item">
                <div className="item-logo logo-cat logo-anim need-anim-scale active"></div>
                <div className="item-tittle">
                  <p>CAT</p>
                </div>
                <div className="item-description">
                  <p>Bonus 375% + 150 FS</p>
                </div>
                <div className="item-buttons">
                  <a href="rulette.html">
                    <button className="button button-orange">GET BONUS</button>
                  </a>
                  <a
                    className="button button-green"
                    href="https://catchthecatkz.com/d467ed7a5"
                    target="_blank"
                  >
                    PLAY
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="item-logo logo-daddy logo-anim need-anim-scale active"></div>
                <div className="item-tittle">
                  <p>DADDY</p>
                </div>
                <div className="item-description">
                  <p>Bonus 375% + 150 FS</p>
                </div>
                <div className="item-buttons">
                  <a href="rulette.html">
                    <button className="button button-orange">GET BONUS</button>
                  </a>
                  <a
                    className="button button-green"
                    href="https://nice-road-two.com/d74076b1c"
                    target="_blank"
                  >
                    PLAY
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="item-logo logo-pinup logo-anim need-anim-scale active"></div>
                <div className="item-tittle">
                  <p>Pin-UP</p>
                </div>
                <div className="item-description">
                  <p>Bonus 375% + 150 FS</p>
                </div>
                <div className="item-buttons">
                  <a href="rulette.html">
                    <button className="button button-orange">GET BONUS</button>
                  </a>
                  <a
                    className="button button-green"
                    href="https://onlinepuonline.com/cwbBKAX7/"
                    target="_blank"
                  >
                    PLAY
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="item-logo logo-izzi logo-anim need-anim-scale active"></div>
                <div className="item-tittle">
                  <p>IZZI</p>
                </div>
                <div className="item-description">
                  <p>Bonus 375% + 150 FS</p>
                </div>
                <div className="item-buttons">
                  <a href="rulette.html">
                    <button className="button button-orange">GET BONUS</button>
                  </a>
                  <a
                    className="button button-green"
                    href="https://izzicasino.life/cf91e09c3"
                    target="_blank"
                  >
                    PLAY
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="item-logo logo-vavada logo-anim need-anim-scale active"></div>
                <div className="item-tittle">
                  <p>VAVADA</p>
                </div>
                <div className="item-description">
                  <p>Bonus 375% + 150 FS</p>
                </div>
                <div className="item-buttons">
                  <a href="rulette.html">
                    <button className="button button-orange">GET BONUS</button>
                  </a>
                  <a
                    className="button button-green"
                    href="https://vavadapartners.pro/?promo=9e1e1391-392b-4e33-87c7-a3523df5e8cf&target=register"
                    target="_blank"
                  >
                    PLAY
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="item-logo logo-kent logo-anim need-anim-scale active"></div>
                <div className="item-tittle">
                  <p>KENT</p>
                </div>
                <div className="item-description">
                  <p>Bonus 375% + 150 FS</p>
                </div>
                <div className="item-buttons">
                  <a href="rulette.html">
                    <button className="button button-orange">GET BONUS</button>
                  </a>
                  <a
                    className="button button-green"
                    href="https://passage-through-trees.com/d753a0bcb"
                    target="_blank"
                  >
                    PLAY
                  </a>
                </div>
              </div>
              <div className="item">
                <div className="item-logo logo-1win logo-anim need-anim-scale active"></div>
                <div className="item-tittle">
                  <p>1WIN</p>
                </div>
                <div className="item-description">
                  <p>Bonus 375% + 150 FS</p>
                </div>
                <div className="item-buttons">
                  <a href="rulette.html">
                    <button className="button button-orange">GET BONUS</button>
                  </a>
                  <a
                    className="button button-green"
                    href="https://1wauah.xyz/#79x5"
                    target="_blank"
                  >
                    PLAY
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section4" id="section4">
        <div className="wrapper">
          <h2 className="section-h2">RECENT WINS</h2>
          <div className="container container-casino">
            <div className="item">{log[0]}</div>
            <div className="item">{log[1]}</div>
            <div className="item">{log[2]}</div>
          </div>
        </div>
      </div>
      <div className="section section5 section-image-and-text">
        <div className="wrapper">
          <div className="container image-second image-middle">
            <div className="item item-info">
              <h2 className="section-h2" id="2">
                Big Bamboo online: a place to meet with luck
              </h2>
              <p>
                If you are interested in the game "Big Bamboo", the official
                website of this game is your best companion to the world of
                panda and bamboo jungle games. Here you will find all the
                necessary details and information about the game provider,
                rules, symbols and bonuses. This site gives you the opportunity
                to plunge into the gameplay and gain experience without risking
                real money.
              </p>
              <div className="column-buttons">
                <a
                  className="button button-green button-anim "
                  href="https://colorful-road-three.com/d35db545f"
                >
                  PLAY
                </a>
                <a href="rulette.html">
                  <button className="button button-orange">
                    GET FREESPINS
                  </button>
                </a>
              </div>
            </div>
            <div className="item item-image">
              <img className="laptop-panda" src={Img2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="section section6 section-image-and-text">
        <div className="wrapper">
          <div className="container img-first image-middle">
            <div className="item item-img img-anim need-anim-scale-fast active">
              <img src={Img3} alt="" />
            </div>
            <div className="item item-info">
              <h2 className="section-h2" id="3">
                <span>Big Bamboo</span> USD: the possibility of big winnings
              </h2>
              <p>
                Playing "Big Bamboo" in rubles is not only exciting
                entertainment, but also a chance for big winnings. Start with a
                minimum bet of 0.10 cents and enjoy the atmosphere of the game,
                where green jungles and mysterious panda symbols are waiting in
                the wings. A maximum bet of $20 USD will open the way to a
                maximum win of 50,000 times your bet.
              </p>
              <div className="column-buttons">
                <a
                  className="button button-green button-anim "
                  href="https://colorful-road-three.com/d35db545f"
                >
                  PLAY
                </a>
                <a href="rulette.html">
                  <button className="button button-orange">FREE MONEY</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section7" id="section7">
        <div className="wrapper">
          <h2 className="section-h2" id="4">
            Game symbols
          </h2>
          <div className="items">
            <div className="item">
              <div className="item-img item-img1"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-img item-img2"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-img item-img3"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-img item-img4"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-img item-img5"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-img item-img6"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-img item-img7"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-img item-img8"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-img item-img9"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="item-img item-img10"></div>
              <div className="item-table">
                <div>
                  <span>8-9</span>
                  <span>100</span>
                </div>
                <div>
                  <span>10-11</span>
                  <span>2500</span>
                </div>
                <div>
                  <span>12+</span>
                  <span>5000</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-review" id="section-review">
        <div className="wrapper">
          <h2 className="section-h2">Characteristics</h2>
          <div className="section-review-block">
            <div className="section-review-block-header">
              <p>Provider</p>
              <p>Psuh Gaming</p>
            </div>
            <div className="section-review-block-main">
              <div>
                <p>Alternative game names</p>
                <p>"Big Bamboo", "Big Bamboo", "Big Bamboo", "Big. Bamboo"</p>
              </div>
              <div>
                <p>Progressive jackpot</p>
                <p>No</p>
              </div>
              <div>
                <p>Minimum bet</p>
                <p>$0.10</p>
              </div>
              <div>
                <p>Maximum bet</p>
                <p>$100</p>
              </div>
              <div>
                <p>Bonuses</p>
                <p>Free spins, scatter, multiplier, collector</p>
              </div>
              <div>
                <p>Setting the number of winning lines</p>
                <p>No</p>
              </div>
              <div>
                <p>Free spins</p>
                <p>Yes</p>
              </div>
              <div>
                <p>Automatic game </p>
                <p>Yes</p>
              </div>
              <div>
                <p>Quick play function</p>
                <p>Yes</p>
              </div>
              <div>
                <p>Risk function (gamble)</p>
                <p>No</p>
              </div>
              <div>
                <p>Number of lines</p>
                <p>50</p>
              </div>
              <div>
                <p>Layout</p>
                <p>6-6-6-6</p>
              </div>
              <div>
                <p>Date of issue</p>
                <p>February 2022</p>
              </div>
              <div>
                <p>RTP</p>
                <p>from 96.13% to 96.94% depending on the functions used</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section9" id="section9">
        <div className="wrapper">
          <h2 className="section-h2" id="5">
            Big Bamboo FAQ
          </h2>
          <div className="container">
            <div className="drop-black  ccc">
              <Collapsfaq title="CAN I PLAY BIG BAMBOO IN DEMO MODE?">
                <div
                  className="drop-select-container dropdownContent "
                  id="myDropdown"
                >
                  <ol>
                    <li>
                      <a href="">
                        Any gambling site that partners with Push Gaming will
                        also offer free access to demo mode.
                      </a>
                    </li>
                  </ol>
                </div>
              </Collapsfaq>
              <Collapsfaq title="WHERE IS THE BEST PLACE TO PLAY BIG BAMBOO SLOT FOR REAL MONEY?">
                <div
                  className="drop-select-container dropdownContent "
                  id="myDropdown"
                >
                  <ol>
                    <li>
                      <a href="">
                        To do this, you need to choose a licensed operator with
                        a good reputation and first-class service. On our
                        website you can find a list of verified online casinos.
                      </a>
                    </li>
                  </ol>
                </div>
              </Collapsfaq>
              <Collapsfaq title="CAN I PLAY BIG BAMBOO SLOT ON MY MOBILE PHONE?">
                <div
                  className="drop-select-container dropdownContent "
                  id="myDropdown"
                >
                  <ol>
                    <li>
                      <a href="">
                        Yes, this slot has a mobile version and can be
                        downloaded to any device.
                      </a>
                    </li>
                  </ol>
                </div>
              </Collapsfaq>
              <Collapsfaq title="WHAT IS THE VOLATILITY OF THE BIG BAMBOO ONLINE SLOT?">
                <div
                  className="drop-select-container dropdownContent "
                  id="myDropdown"
                >
                  <ol>
                    <li>
                      <a href="">
                        The Big Bamboo slot has a fairly high volatility, where
                        the reels can spin several times between wins, although
                        the prizes are often quite generous. Over the long term,
                        it pays out 96.13 for every 100 bets.
                      </a>
                    </li>
                  </ol>
                </div>
              </Collapsfaq>
              <Collapsfaq title="CAN I PLAY BIG BAMBOO FOR US DOLLARS?">
                <div
                  className="drop-select-container dropdownContent "
                  id="myDropdown"
                >
                  <ol>
                    <li>
                      <a href="">
                        Yes, almost all online casinos offer the opportunity to
                        play for USD and other currencies.
                      </a>
                    </li>
                  </ol>
                </div>
              </Collapsfaq>
            </div>
          </div>
        </div>
      </div>
      <div className="section section10 section-image-and-text">
        <div className="wrapper">
          <div className="container image-second image-middle">
            <div className="item item-info">
              <h2 className="section-h2">
                <span>Big Bamboo</span> Casino: the place where luck will find
                you
              </h2>
              <p>
                The game's instant win comes in the form of a multiplier that
                can range from 1x to an impressive 5,000x your initial bet.
                Additionally, the game has a Collector feature that collects the
                value of all other Instant Prize symbols or Collector symbols in
                the player's field of view. This manifold remains in place
                throughout the Golden Bamboo function, even when the other
                positions are rotated.
              </p>
              <div className="column-buttons">
                <a
                  className="button button-green button-anim "
                  href="https://colorful-road-three.com/d35db545f"
                >
                  PLAY
                </a>
                <a href="rulette.html">
                  <button className="button button-orange">
                    DEPOSIT BONUS
                  </button>
                </a>
              </div>
            </div>
            <div className="item item-image">
              <img className="laptop-panda" src={Img4} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="section section11 section-image-and-text">
        <div className="wrapper">
          <div className="container img-first image-middle">
            <div className="item item-img img-anim need-anim-scale-fast active">
              <img src={Img5} alt="" />
            </div>
            <div className="item item-info-white">
              <h2 className="section-h2">Big Bamboo online: play and win</h2>
              <p>
                Online games "Big Bamboo" are an opportunity to play and win
                exciting adventures with pandas and bamboo. Try your luck, spin
                the reels, and perhaps luck will smile on you, giving you big
                wins.
              </p>
              <div className="column-buttons">
                <a
                  className="button button-green button-anim "
                  href="https://colorful-road-three.com/d35db545f"
                >
                  PLAY
                </a>
                <a href="rulette.html">
                  <button className="button button-orange">FREE SPINS</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section12 section-image-and-text">
        <div className="wrapper">
          <div className="container image-second image-middle">
            <div className="item item-info">
              <h2 className="section-h2">
                Play <span>Big Bamboo</span> online: complete your journey
              </h2>
              <p>
                Immerse yourself in the Big Bamboo game online and complete your
                journey in the bamboo jungle. Meet mysterious symbols, trigger
                bonus rounds and feel the joy of winnings. Get ready for an
                exciting gaming experience!
              </p>
              <div className="column-buttons">
                <a
                  className="button button-green button-anim "
                  href="https://colorful-road-three.com/d35db545f"
                >
                  PLAY
                </a>
                <a href="rulette.html">
                  <button className="button button-orange">FREE MONEY</button>
                </a>
              </div>
            </div>
            <div className="item item-image">
              <img className="bamboo-img" src={Img6} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="section section13 section-image-and-text">
        <div className="wrapper">
          <div className="container img-first image-middle">
            <div className="item item-img img-anim need-anim-scale-fast active">
              <img src={Img7} alt="" />
            </div>
            <div className="item item-info-white">
              <h2 className="section-h2" id="6">
                Important symbols in the game
              </h2>
              <ul>
                <li>Collector</li>
                <p>
                  Its task is to collect the value of other symbols and provide
                  the player with decent winnings.
                </p>
                <br />
                <li>Multiplier from x2 to x10</li>
                <p>
                  These multipliers are applied to the values ​​of the Instant
                  Prize symbols or Collector symbols, increasing the player's
                  potential winnings.
                </p>
                <br />
                <li>Launching free spins</li>
                <p>
                  Free spins in the game can be launched using scatter symbols,
                  which can appear either naturally or be opened by the “Golden
                  Bamboo” feature. Depending on their location on the reels, the
                  player can receive from 4 to 10 free spins.
                </p>
                <br />
                <li>Scatter symbol</li>
              </ul>
              <p>
                The scatter symbol is another important detail of the game. When
                it lands, the player receives 4 additional free spins. If the
                second, third or fourth scatter symbol also appears, the player
                receives additional free spins and for each symbol converted, a
                multiplier is awarded and applied to the player's winnings.
              </p>
              <div className="column-buttons">
                <a
                  className="button button-green button-anim "
                  href="https://colorful-road-three.com/d35db545f"
                >
                  PLAY
                </a>
                <a href="rulette.html">
                  <button className="button button-orange">GET A BONUS</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section logo-center">
        <img src={Img8} alt="" />
      </div>
    </div>
  );
};

export default Homea;
