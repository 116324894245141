import React, { useEffect, useState } from "react";
import "./Footer.scss";
import footerLogo1 from "../../assets/footerLogo1.svg";
import footerLogo2 from "../../assets/footerLogo2.svg";
import footerLogo3 from "../../assets/footerLogo3.svg";
import footerLogo4 from "../../assets/footerLogo4.svg";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <footer>
      <div className="footer-container ">
        <div className="footer-container-top ">
          <div className="footer-container-top-links center">
            <div>
              <p>BIG BAMBOO</p>
              <a href="rulette.html">Home Page</a>
              <a href="rulette.html">Play</a>
              <a href="rulette.html">Play For money</a>
            </div>
            <div>
              <p>How To Play</p>
              <a href="rulette.html">Demo</a>
              <a href="rulette.html">Slot</a>
              <a href="rulette.html">Machine</a>
            </div>
            <div>
              <p>About Company</p>
              <a href="rulette.html">About Us</a>
              <a href="rulette.html">Privacy policy</a>
              <a href="rulette.html">Contacts</a>
            </div>
            <div>
              <p>COPYRIGHT</p>
              <a>bigbamboo@gmail.com</a>
              <a>{currentYear} © Big Bamboo</a>
            </div>
          </div>
          <div className="footer-container-top-logos ">
            <img src={footerLogo1} alt="" />
            <img src={footerLogo2} alt="" />
            <img src={footerLogo3} alt="" />
            <img src={footerLogo4} alt="" />
          </div>
        </div>
        <div className="footer-container-bottom">
          <div className="center">
            <p>bigbamboo-slot.info © {currentYear} Big Bamboo Official Site</p>
            <p>
              Information provided by big bamboo ("Company", "we", "us", "our",
              "we") about Big Bamboo (the "Site"), is intended solely for
              general information purposes. All information on the Site is
              provided in good faith, but we make no representations or
              warranties, express or implied, regarding the accuracy, adequacy,
              validity, reliability, availability or completeness of any
              information on the Site. Under no circumstances will we be liable
              to you for any loss or damage of any kind incurred as a result of
              your use of the Site or reliance on any information provided on
              the Site. The use of the Site and the reliance on any information
              on the Site is solely at your own risk.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
